import { render, staticRenderFns } from "./LikeItemListLayout.vue?vue&type=template&id=326c55c2&scoped=true"
import script from "./LikeItemListLayout.vue?vue&type=script&lang=js"
export * from "./LikeItemListLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326c55c2",
  null
  
)

export default component.exports